import React, { useState, useRef, useEffect } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import Cancel from "./images/Cancel_1.png";
import ArthaFormControl from "../../fields/ArthaFormControl";
import { InputLabel, MenuItem, Select } from "@mui/material";

const UploadSupportiveBL = ({ open, handleClose, existingDocuments, onRemove, isShopActNumberPresent }) => {
    
    const [files, setFiles] = useState([]);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [disabledDocTypes, setDisabledDocTypes] = useState(new Set());
    const [isEditing, setIsEditing] = useState(false);
    const hiddenFileInput = useRef(null);
  
    const requiredDocuments = [
      "Photo of Firm / Business (Outside Premises with business name)",
      "Photo of Firm / Business (Inside Premises)",
      "Firm / Business Address Proof",
      ...(isShopActNumberPresent ? ["Shop Act License / Udyam Aadhaar proof"] : []),
    ];
  
    useEffect(() => {
      if (open) {
        if (existingDocuments && existingDocuments.length > 0) {
          const uniqueDocuments = existingDocuments.filter((doc, index, self) =>
            index === self.findIndex((t) => t.documentName === doc.documentName)
          );
          // Convert existing documents from base64 to File objects
          const fileList = uniqueDocuments.map(doc => {
            const byteCharacters = atob(doc.documentData); // Decode base64
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            return new File([new Blob([byteArray], { type: 'application/octet-stream' })], doc.documentName);
          });
          setFiles(fileList);
          setDocumentTypes(uniqueDocuments.map(doc => doc.documentType));
          setDisabledDocTypes(new Set(uniqueDocuments.map(doc => doc.documentType))); // Set disabled types
          setIsEditing(true);
        } else {
          setFiles([]);
          setDocumentTypes([]);
          setDisabledDocTypes(new Set());
          setIsEditing(false);
        }
      }
    }, [open, existingDocuments]);
  
    const handleFileChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        setFiles(prevFiles => [...prevFiles, file]);
        setDocumentTypes(prevDocTypes => [...prevDocTypes, ""]);
        setIsEditing(true);
      }
    };
  
    const handleDeleteFile = (index) => {
      setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
      setDocumentTypes(prevDocTypes => prevDocTypes.filter((_, i) => i !== index));
      setIsEditing(true);
    };
  
    const handleDocumentTypeChange = (index, value) => {
      setDocumentTypes(prevDocTypes => {
        const updatedDocTypes = [...prevDocTypes];
        updatedDocTypes[index] = value;
        return updatedDocTypes;
      });
  
      // Update disabled types if it's a new selection
      if (value) {
        setDisabledDocTypes(prevSet => new Set(prevSet.add(value)));
      }
    };
  
    const handleSave = () => {
      const promises = files.map((file, index) => {
        return new Promise((resolve, reject) => {
          fileToBase64(file, (base64Data) => {
            if (base64Data) {
              resolve({
                documentType: documentTypes[index] || "",
                documentName: file.name,
                documentData: base64Data,
              });
            } else {
              reject(new Error(`Failed to convert file ${file.name} to base64`));
            }
          });
        });
      });
  
      Promise.all(promises)
        .then((documentsInfo) => {
          const uniqueDocuments = documentsInfo.filter((doc, index, self) =>
            index === self.findIndex((t) =>
              t.documentType === doc.documentType && t.documentName === doc.documentName
            )
          );
  
          handleClose(uniqueDocuments);
          setFiles([]);
          setDocumentTypes([]);
          setDisabledDocTypes(new Set()); // Reset disabled types
          setIsEditing(false);
        })
        .catch((error) => {
          console.error('Error saving documents:', error);
          // Handle error (e.g., show notification to the user)
        });
    };
  
    function fileToBase64(file, callback) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1]; // Extract base64 data from result
        callback(base64String);
      };
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
        callback(null); // Indicate error
      };
      reader.readAsDataURL(file); // Convert file to base64
    }
  
    const isContinueEnabled = requiredDocuments.every(docType =>
      files.some((_, index) => documentTypes[index] === docType)
    );
  
    const renderButtons = () => {
      return (
        <div className="row mt-4">
          <div className="col-6 d-flex justify-content-end">
            <ArthaClearButton
              type="button"
              className="custom_btn"
              onClick={() => {
                setFiles([]);
                setDocumentTypes([]);
                setDisabledDocTypes(new Set());
                setIsEditing(false);
                handleClose([]);
              }}
            >
              Cancel
            </ArthaClearButton>
          </div>
          <div className="col-6 d-flex justify-content-start">
            <ArthaContinueButton
              type="button"
              className="orange custom_btn"
              onClick={handleSave}
              disabled={!isContinueEnabled}
            >
              Continue
            </ArthaContinueButton>
          </div>
        </div>
      );
    };
  
    return (
      <Dialog open={open} onClose={() => handleClose([])} maxWidth="lg">
        <DialogContent>
          <div className="row">
            <div className="col-md-8 mt-3 mt-md-4 mx-auto">
              {existingDocuments && existingDocuments.length > 0 && !isEditing && (
                <div className="row">
                  {existingDocuments.map((doc, index) => (
                    <div className="col-12" key={index}>
                      <p>{doc.documentType}</p>
                      <img
                        src={Cancel}
                        style={{ width: "20px" }}
                        alt="Cancel"
                        onClick={() => onRemove(doc.documentType)}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          {files.map((file, index) => (
            // <div key={index} className="uploadadhar mt-4 row">
            //   <div className="uploaded-image-container col-8 d-flex">
            //     <div className="col-md-8 mt-3 mt-md-2 mx-auto ">
            <div key={index} className="uploadadhar mt-4 row d-flex">
            {/* <div className="uploaded-image-container col-12 "> */}
              <div className="col-md-6 mt-3 mt-md-4 mx-auto">
                  <ArthaFormControl  >
                    <InputLabel id={`documentType-label-${index}`}>Type of Document</InputLabel>
                    <Select
                      labelId={`documentType-label-${index}`}
                      id={`documentType-${index}`}
                      name={`documentType-${index}`}
                      value={documentTypes[index] || ""}
                      onChange={(e) => handleDocumentTypeChange(index, e.target.value)}
                      disabled={disabledDocTypes.has(documentTypes[index])} // Disable if type is already used
                    >
                      {requiredDocuments.map((docType, i) => (
                        <MenuItem key={i} value={docType} >
                          {docType}
                        </MenuItem>
                      ))}
                    </Select>
                  </ArthaFormControl>
                </div>
                <div className="col-6 d-flex mt-2">
                  <span style={{ marginRight: "10px" }}>
                    {file.name}
                  </span>
                  <span
                    onClick={() => handleDeleteFile(index)}
                    title="Delete"
                    style={{ width: "50px" }}
                  >
                    <img src={Cancel} alt="Cancel" />
                  </span>
                </div>
              </div>
            // {/* </div> */}
          ))}
          
          {/* <div className="uploadadhar mt-4 row  col-8 d-flex">
            <div className="col-md-8 mt-3 mt-md-2 mx-auto"> */}
             <div className="uploadadhar mt-4 row">
            {/* <div className="col-md-4 mt-3 mt-md-2 mx-auto"> */}
            <div className="col-md-6 mt-3 mt-md-4 mx-auto">
              <ArthaFormControl fullWidth>
                <InputLabel id="documentType-label-new">Type of Document</InputLabel>
                <Select
                  labelId="documentType-label-new"
                  id="documentType-new"
                  name="documentType-new"
                  value={documentTypes[files.length] || ""}
                  onChange={(e) => handleDocumentTypeChange(files.length, e.target.value)}
                  // disabled={disabledDocTypes.has(documentTypes[files.length])} // Disable if type is already used
                >
                  {requiredDocuments.map((docType, index) => (
                    <MenuItem key={index} value={docType} >
                      {docType}
                    </MenuItem>
                  ))}
                </Select>
              </ArthaFormControl>
            </div>
            <div className="col-6 d-flex justify-content-start mt-2 ">
              <span>
                <label
                  htmlFor="fileInput"
                  className="upload-label d-flex justify-content-start"
                >
                  <span className="upload-icon">
                    <MdOutlineFileUpload />
                  </span>
                  <span className="mt-1 ">Upload</span>
                </label>
                <input
                  ref={hiddenFileInput}
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  onClick={(e) => (e.target.value = null)}
                />
              </span>
            </div>
          </div>
          {renderButtons()}
        </DialogContent>
      </Dialog>
    );
  };
export default UploadSupportiveBL;
