import React, { useEffect, useState, useMemo, useRef } from "react";
// import { useTable, useSortBy, usePagination } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
// import "./override.css";
import card from "../../Login/public/card1.svg";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
// import MessagePopup from "../messagePopup";
import ArthaCancelButton from "../../fields/ArthaCancelButton";
import ArthaApplyButton from "../../fields/ArthaApplyButton";
import { handleSignOut } from "./SessionExpiredApi";
import { Dialog } from "@mui/material";
import ArthaTextField from "../../fields/ArthaTextField";
import LoadingSpinner from "../../fields/spinner";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import TermsPopup from "../../Login/TermsPopup";
import { Link } from "react-router-dom";
import ArthaFormControl from "../../fields/ArthaFormControl.js";
import ArthaInputLabel from "../../fields/ArthaInputLabel.js";
import { Select, MenuItem, InputLabel } from "@mui/material";

import {
  propertyLoanEmailOtpValidation,
  propertyLoanFetchDataAPI,
  propertyLoanStoreApplicantDetails,
  propertyLoanFetchApplicantSummeryAPI,
  propertyLoanMobileOtpValidation,
} from "../../actioncreators/actioncreators";
import {
  calculatedIncomeDetailsPayload,
  editedIncomeAssessmentPayload,
} from "../../actions/loanAction";
const ApplicantSummaryDetails = ({ onBack, onContinue, changeStatus }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = React.useState({
    officialEmail: "",
  });
  const [isDeceased, setIsDeceased] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [response, setResponse] = useState();
  const navigate = useNavigate();
  const [isAgreed, setIsAgreed] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(true);
  const [isOpen, setIsOpen] = React.useState(false);
  const [tenureDropdown, setTenureDropdown] = React.useState([]);
  const [isValidMobile, setIsValidMobile] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isValidPanCard, setIsValidPanCard] = useState(true);
  const [mobile, setMobile] = useState("");
  const [panCard, setPanCard] = useState("");
  const [employmentType, setemploymentType] = useState("");

  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [industryType, setIndustryType] = useState("");
  const [businessCashflowConfigId, setBusinessCashflowConfigId] = useState("");
  const [fieldErrors, setFieldErrors] = useState({});
  const [categoryProductMap, setCategoryProductMap] = useState({});
  const [unitOfMeasurementOptions, setUnitOfMeasurementOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const response3 = useSelector((state) => state.caseDetails);
  const editedResponse = useSelector((state) => state.editedIncomeAssessment);
  const response4 = useSelector((state) => state.STPNSTPDetails);
  console.log("  editedResponse?.CaseId", editedResponse?.CaseId);
  console.log("  caseDetails?.CaseId", response3?.caseDetails?.[0]?.CaseId);
  const response5 = useSelector((state) =>
    editedResponse?.CaseId === response3?.caseDetails?.[0]?.CaseId &&
    editedResponse
      ? state.editedIncomeAssessment
      : state.fetchIncomeAssessment
  );

  const [localEmployees, setLocalEmployees] = useState([
    {
      id: 1,
      applicant_id: response?.ApplicantDetails?.applicant_id,
      name: "",
      status: "",
      measureFrequency: "",
      numberDaysInMonth: "",
      productOrServiceCount: "",
      unitSellingPrice: "",
      businessCashFlowConfigId: "",
    },
  ]);

  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };

  useEffect(() => {
    const fetchApplicantSummary = async () => {
      try {
        const response5 = await propertyLoanFetchApplicantSummeryAPI();

    //    let response5 = {
    //     "httpResponseCode": 200,
    //     "businessStatusCode": 2,
    //     "message": "Applicant details retrived successfully",
    //     "ApplicantDetails": [
    //         {
    //             "applicant_id": "4be088d1-4dd7-4444-a5d4-bcbd52cc95f1",
    //             "status": "",
    //             "ap_id": "2632f641-d22e-4ba6-b721-e8c0df86674d",
    //             "name": "PRASANN YOGESH HIREMATH",
    //             "pan": "AZIPH4434N"
    //         },{
    //           "applicant_id": "4be088d1-4dd7-4444-a5d4-bcbd52cc95f1",
    //           "status": "",
    //           "ap_id": "2632f641-d22e-4ba6-b721-e8c0df86674d",
    //           "name": "PRASANN YOGESH HIREMATH",
    //           "pan": "AZIPH4434N"
    //       }
    //     ]
    // }
        setResponse(response5);
        setLocalEmployees(response5?.ApplicantDetails);
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching address vintage:", error);
      }

      const formattedEmployees =
        response5?.incomeAssessmentResponse?.business_cashflow_config_details?.businessCashflowConfigDetails?.map(
          (employee, index) => ({
            ...employee,
            id: index + 1,
            name: employee.productsList ? employee.productsList[0] : "",
            status: "",
            measureFrequency: "",
            numberDaysInMonth: "",
            productOrServiceCount: "",
            unitSellingPrice: "",
          })
        );

      setCategoryProductMap(
        formattedEmployees?.reduce((acc, item) => {
          acc[item.applicant_id] = {
            products: item.productsList || [],
            unitsOfMeasurement: item.unitsOfMeasurement || [],
            profitPercentage: item.profitPercentage || 0,
          };
          return acc;
        }, {})
      );

      setUnitOfMeasurementOptions([]);
    };

    fetchApplicantSummary();
  }, []);

  const handleBack = () => {
    onBack();
  };

  // Handle input change
  const handleChange = (e, id) => {
    const { name, value } = e.target;
    const updatedEmployees = localEmployees.map((employee) =>
      employee.id === id ? { ...employee, [name]: value } : employee
    );
    setLocalEmployees(updatedEmployees);
  };

  const handleSubmit = async () => {
    navigate("/home/override");
  };

  // Handle form submission
  const handleCalculate = async () => {
    // Clear previous errors
    // setErrors([]);
    setFieldErrors({});

    // Initialize an empty errors array
    let validationErrors = [];
    let fieldValidationErrors = {};

    // Validate each employee entry
    localEmployees.forEach((employee, index) => {
      if (!employee.applicant_id) {
        validationErrors.push(
          `Product Category is required for entry ${index + 1}`
        );
        fieldValidationErrors[`applicant_id-${index}`] = true;
      }
      if (!employee.status) {
        validationErrors.push(
          `Unit of Measurement is required for entry ${index + 1}`
        );
        fieldValidationErrors[`status-${index}`] = true;
      }
      if (!employee.measurementFrequency) {
        validationErrors.push(
          `Measurement Frequency is required for entry ${index + 1}`
        );
        fieldValidationErrors[`measurementFrequency-${index}`] = true;
      }
      if (!employee.numberDaysInMonth) {
        validationErrors.push(
          `Number of Days in a Month is required for entry ${index + 1}`
        );
        fieldValidationErrors[`numberDaysInMonth-${index}`] = true;
      }
      if (!employee.productOrServiceCount) {
        validationErrors.push(
          `Product/Service Count is required for entry ${index + 1}`
        );
        fieldValidationErrors[`productOrServiceCount-${index}`] = true;
      }
      if (!employee.unitSellingPrice) {
        validationErrors.push(
          `Unit Selling Price is required for entry ${index + 1}`
        );
        fieldValidationErrors[`unitSellingPrice-${index}`] = true;
      }
    });

    // If there are errors, set the errors state and do not proceed
    if (validationErrors.length > 0) {
      // setErrors(validationErrors);
      setFieldErrors(fieldValidationErrors);
      return;
    }
    setIsLoading(true);
    try {
      const payload = {
        source: "IA",
        iaJson: {
          products: localEmployees.map((employee) => {
            const profitPercentage =
              categoryProductMap[employee.applicant_id]?.profitPercentage;
            return {
              measurementFrequency: employee.measurementFrequency,
              numberDaysInMonth: parseInt(employee.numberDaysInMonth, 10),
              applicant_id: employee.applicant_id,
              productOrServiceCount: parseInt(
                employee.productOrServiceCount,
                10
              ),
              name: employee.name,
              status: employee.status,
              unitSellingPrice: parseFloat(employee.unitSellingPrice),
              profitPercentage: profitPercentage,
            };
          }),
          businessCategory: businessName,
          businessCashflowConfigId: businessCashflowConfigId,
          industryType: industryType,
        },
      };

      const payload1 = {
        CaseId: response3?.caseDetails?.[0]?.CaseId,
        incomeAssessmentResponse: response5.incomeAssessmentResponse,
        userIncomeAssessment: {
          products: localEmployees.map((employee) => {
            const profitPercentage =
              categoryProductMap[employee.applicant_id]?.profitPercentage;
            return {
              measurementFrequency: employee.measurementFrequency,
              numberDaysInMonth: parseInt(employee.numberDaysInMonth, 10),
              applicant_id: employee.applicant_id,
              productOrServiceCount: parseInt(
                employee.productOrServiceCount,
                10
              ),
              name: employee.name,
              status: employee.status,
              unitSellingPrice: parseFloat(employee.unitSellingPrice),
              profitPercentage: profitPercentage,
            };
          }),
          businessCategory: businessName,
          businessCashflowConfigId: businessCashflowConfigId,
          industryType: industryType,
        },
      };

      console.log("payload1", payload1);
      // dispatch(editedIncomeAssessmentPayload(payload1));
      // dispatch(calculatedIncomeDetailsPayload(payload));

      // const response = await calculateOverrideIncomeAssessment(payload);
      // dispatch(calculatedIncomeDetails(response));
      if (response.businessStatusCode === 2) {
        const formatNumber = (number) => {
          return new Intl.NumberFormat("en-IN", {
            // minimumFractionDigits: 2,
            // maximumFractionDigits: 2
          }).format(number);
        };

        const detail = response4.stpNstpDetails.find(
          (item) => item.Parameter === "P342"
        );
        const actualValue = detail ? detail.ActualValue : 0;

        const originalIncome = formatNumber(actualValue);
        const revisedIncome = formatNumber(response.income);

        Swal.fire({
          icon: "success",
          title: "Income Calculated Successfully!",
          html: `
              <p>Original income entered by Applicant: <b>Rs ${originalIncome}</b></p>
              <p>Revised income entered by Ops User: <b>Rs ${revisedIncome}</b></p>
          `,
          showConfirmButton: true,
          confirmButtonText: "Submit",
          confirmButtonColor: "#ff7300",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          cancelButtonColor: "#ffffff",
          customClass: {
            cancelButton: "custom-cancel-button",
            confirmButton: "custom-confirm-button",
          },
          didOpen: () => {
            const confirmButton = Swal.getConfirmButton();
            const cancelButton = Swal.getCancelButton();
            confirmButton.style.width = "120px";
            confirmButton.style.marginLeft = "10px";
            cancelButton.style.width = "120px";
            cancelButton.style.background = "white";
            cancelButton.style.color = "black";
            cancelButton.style.border = "1px solid black";
            cancelButton.style.marginLeft = "10px";
          },
        }).then((result) => {
          if (result.isConfirmed) {
            handleSubmit();
          }
        });
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = (index) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        setLocalEmployees(localEmployees.filter((_, idx) => idx !== index));
        Swal.fire({
          icon: "success",
          title: "Deleted!",
          text: "Your data has been deleted.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };

  const handleUpdate = (index) => {

  };

  // Helper function to handle errors
  const handleError = (error) => {
    if (error.response && error.response.status === 401) {
      toast.error("Your session has expired. Please log in again.");
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error("Too many requests. Please try again later.");
    } else {
      toast.error(
        "Technical issue connecting to servers. Please try again later."
      );
    }
  };

  const onChange = (e, index) => {
    const { name, value } = e.target;
    const updatedEmployees = localEmployees.map((employee, idx) => {
      if (idx === index) {
        if (name === "applicant_id") {
          const selectedCategory = categoryProductMap[value];
          return {
            ...employee,
            [name]: value,
            name: "",
            businessCashFlowConfigId: selectedCategory
              ? selectedCategory.businessCashFlowConfigId
              : "",
          };
        } else {
          return { ...employee, [name]: value };
        }
      }
      return employee;
    });
    setLocalEmployees(updatedEmployees);

    // Remove error if the field is valid
    if (value.trim() !== "") {
      setFieldErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[`${name}-${index}`];
        return updatedErrors;
      });
    } else {
      // Add error if the field is invalid
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [`${name}-${index}`]: true,
      }));
    }
  };

  //email dialog code started
  const [isOpenEmailDialog, setOpenEmailDialog] = useState(false);
  const [isEmailAdded, setEmailAdded] = React.useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [isInvalidOtp, setIsInvalidOtp] = useState(true);
  const inputRefs = useRef([]);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleInputKeyDown = (index, e) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      // Move focus to the previous input on Backspace
      inputRefs.current[index - 1].focus();
    }
  };
  const closeEmailDialog = () => {
    setOpenEmailDialog(false);
    if (!isEmailAdded) {
      setFormData({
        ...formData,
        officialEmail: "",
      });
    }
  };

  const handleVerifyClick = async (e) => {
    // onContinue();
    
  
    setIsButtonClicked(true);
    setIsLoading2(true);

    e.preventDefault();

    setOpenOTPDialog(false);
    setOpenEmailDialog(false);

    const requestBody = {
      otp: otp.join(""), // Join the otp array into a string
      pan: panCard || "",
      mobile: mobile,
      employmentType:employmentType,
    };

    if (otp.join("") === "") {
      // Check if otp is empty
      setIsInvalidOtp(true);
      inputRefs.current[0].current.focus();
      return;
    }

    try {
      const response = await propertyLoanMobileOtpValidation(requestBody);
      if (
        response.data.httpResponseCode === 200 &&
        response.data.businessStatusCode === 2
      ) {
        // setOpenOTPDialog(false);
        // setOpenEmailDialog(false);
        changeStatus("PERSONAL_DETAILS_BUSINESS");
        toast.success("Applicant Details Added Successfully.");
      } else {
        console.log("Unexpected response status:", response.status);
        setEmailAdded(false);
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error during OTP verification:", error);
    } finally {
      // Reset loading state after the operation is complete
      setIsLoading2(false);
      setIsButtonClicked(false);
    }
    //TODO1 remove below two lines
    // setOpenOTPDialog(false);
    // setEmailAdded(true);
  };

  const [isOpenOTPDialog, setOpenOTPDialog] = useState(false);
  const openOTPDialog = async () => {
    setIsButtonClicked(true);
    setIsLoading1(true);
    setOtp(["", "", "", "", "", ""]);
    //TODO1 remove below line
    setOpenOTPDialog(true);
    const requestBody = { deceased: isDeceased,pan: panCard || "",mobile: mobile || "", employmentType:employmentType|| "",  };
    try {
      const response = await propertyLoanStoreApplicantDetails(requestBody);
      if (
        response.data.httpResponseCode === 200 &&
        response.data.businessStatusCode === 2
      ) {
        // setOpenOTPDialog(true);
      } else {
        console.log(
          "Unexpected response status:",
          response.data.httpResponseCode
        );
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error during OTP verification:", error);
    } finally {
      // Reset loading state after the operation is complete
      setIsLoading1(false);
      setIsButtonClicked(false);
    }
  };

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (index < otp.length - 1 && value !== "") {
      // Move focus to the next input if not on the last input and a digit is entered
      inputRefs.current[index + 1].focus();
    }
    const isAllFieldsFilled = newOtp.every((otpValue) => otpValue !== "");
    // Enable or disable the "Continue" button based on the OTP fields status
    setIsInvalidOtp(!isAllFieldsFilled);
  };

  const isEmailValid = (email) => {
    return emailRegex.test(email);
  };

  const fetchTenureValues = async () => {
    setTenureDropdown([]);
    try {
      let payload = {
        source: "json_value",
        key: "EMPLOYMENT_TYPE",
        type: "LOAN_AGAINST_PROPERTY",
      };
      const response = await propertyLoanFetchDataAPI(payload);
      setTenureDropdown(response?.data?.[0]?.List);
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching address vintage:", error);
    }
  };

  useEffect(() => {
    fetchTenureValues();
  }, []);
  //email dialog code ended

  useEffect(() => {
    setIsButtonDisabled(!(isValidMobile && isValidPanCard && isTermsAccepted));
    console.log("isButtonDisabled:", isButtonDisabled);
  }, [isValidMobile, isValidPanCard, isTermsAccepted, isCheckboxDisabled]);

  const handleMobileChange = (e) => {
    const enteredMobile = e.target.value;
    const isNumeric = /^[0-9]+$/.test(enteredMobile);

    if (isNumeric || enteredMobile === "") {
      setMobile(enteredMobile);
      setIsValidMobile(enteredMobile.length === 10);
      // setIsCheckboxDisabled(!(enteredMobile.length === 10 && isValidPanCard && !isTermsAccepted));
      // setIsCheckboxDisabled(!enteredMobile );
    }
  };


  const handlePanCardChange = (e) => {
    const enteredPanCard = e.target.value.toUpperCase(); // Convert to uppercase
    setPanCard(enteredPanCard);
    const panCardRegex = /^[A-Z]{5}\d{4}[A-Z]{1}$/;
    setIsValidPanCard(panCardRegex.test(enteredPanCard));
    //setIsCheckboxDisabled(!(isValidMobile && panCardRegex.test(enteredPanCard) && !isTermsAccepted));
    // setIsCheckboxDisabled(!enteredPanCard );
  };

  const handleChangeEmp = (e) => {
    const enteredPanCard = e.target.value; // Convert to uppercase
    setemploymentType(enteredPanCard);
    // const panCardRegex = /^[A-Z]{5}\d{4}[A-Z]{1}$/;
    // setIsValidPanCard(panCardRegex.test(enteredPanCard));
    //setIsCheckboxDisabled(!(isValidMobile && panCardRegex.test(enteredPanCard) && !isTermsAccepted));
    // setIsCheckboxDisabled(!enteredPanCard );
  };

  // const handleAgreeChange = () => {
  //   setIsAgreed(true);
  //   setIsCheckboxDisabled(!(isValidMobile && isValidPanCard && isAgreed));
  // };
  const handleAgreeChange = (e) => {
    const isChecked = e.target.checked;
    setIsDeceased(isChecked);
    setIsAgreed(isChecked);
    setIsCheckboxDisabled(!isChecked && !(isValidMobile && isValidPanCard)); // Enable fields if checkbox is unchecked
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openPopup = () => {
    if (mobile && panCard) {
      setIsOpen(true);
    }
  };

  const handleAcceptTerms = () => {
    setIsTermsAccepted(true);
    setIsCheckboxDisabled(true);
    closeModal();
  };

  return (
    <div
      className="row"
      style={{
        border: "1px solid gray",
        borderRadius: "5px",
        marginTop: "15px",
      }}
    >
      <div className="col-12 mt-2">
        <h5 style={{ fontWeight: "bold" }}> Applicants Summary</h5>
      </div>

      <div className="col-12 mt-2">
        <span>
          First Applicant details are shown here by default. Please provide
          further details related to this Applicant. <br />
          You can add other Co-applicant(s) using 'Add Applicant' button.
        </span>
      </div>

      <div className="table-responsive mt-4">
        <table className=" table-striped">
          <thead>
            <tr
              style={{
                borderBottom: "1px solid",
                backgroundColor: "lightgray",
                lineHeight: "30px",
                justifyContent: "center",
              }}
            >
              <th className="col-3" style={{ backgroundColor: "lightgrey" }}>
                Applicant ID
              </th>
              <th className="col-2" style={{ backgroundColor: "lightgrey" }}>
                Name
              </th>
              <th className="col-1" style={{ backgroundColor: "lightgrey" }}>
                Life Status
              </th>
              <th className="col-1" style={{ backgroundColor: "lightgrey" }}>
                PAN
              </th>
              <th className="col-1" style={{ backgroundColor: "lightgrey" }}>
                Status
              </th>
              <th
                className="col-2 text-start"
                style={{ backgroundColor: "lightgrey" }}
              >
                Actions
              </th>
            </tr>
          </thead>

          <tbody>
            {localEmployees?.map((employee, index) => (
              <tr
                key={index}
                style={{
                  borderBottom: "1px solid lightGray",
                  margin: "3px",
                  lineHeight: "50px",
                }}
              >
                <td
                  className={`col-3 ${
                    fieldErrors[`applicant_id-${index}`] ? "is-invalid" : ""
                  }`}
                >
                  {employee.applicant_id}
                </td>

                <td
                  className={`col-2 ${
                    fieldErrors[`name-${index}`] ? "is-invalid" : ""
                  }`}
                >
                  {employee.name}
                </td>

                <td
                  className={`col-1 ${
                    fieldErrors[`life_status-${index}`] ? "is-invalid" : ""
                  }`}
                >
                  {employee.life_status}
                </td>

                <td
                  className={`col-1 ${
                    fieldErrors[`pan-${index}`] ? "is-invalid" : ""
                  }`}
                >
                  {employee.pan}
                </td>

                <td
                  className={`col-1 ${
                    fieldErrors[`status-${index}`] ? "is-invalid" : ""
                  }`}
                >
                  {employee.status}
                </td>

                <td
        className={`col-2 ${
          fieldErrors[`action-${index}`] ? "is-invalid" : ""
        }`}
      >
        <ArthaCancelButton
          className="col-1"
          onClick={() => handleUpdate(index)}
          style={{ width: "50px", fontSize: "11px", height: "25px" }}
        >
          Update
        </ArthaCancelButton>

        {/* Conditionally render or disable the Delete button */}
        {index === 0 ? (
          <ArthaContinueButton
            className="col-1"
            style={{
              width: "50px",
              fontSize: "11px",
              height: "25px",
              cursor: "not-allowed",
              backgroundColor: "#cccccc", // Optional: make the button appear disabled
            }}
            disabled
          >
            Delete
          </ArthaContinueButton>
        ) : (
          <ArthaContinueButton
            className="col-1"
            onClick={() => handleDelete(index)}
            style={{ width: "50px", fontSize: "11px", height: "25px" }}
          >
            Delete
          </ArthaContinueButton>
        )}
      </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="d-flex justify-content-start">
          <button
            // onClick={addRow}
            onClick={() => {
              setOpenEmailDialog(true);
            }}
            style={{
              color: "white",
              fontSize: "12px",
              backgroundColor: "#ff7300", // Add a background color for better visibility
              width: "140px", // Set your desired width
              height: "35px", // Set your desired height
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "none", // Remove default border
              borderRadius: "20px", // Optional: Add border-radius for rounded corners
              marginTop: "25px",
            }}
          >
            Add Applicant
          </button>
        </div>
        <p className="mt-3">
          <strong>Note: </strong> When an existing applicant is updated, please
          make sure that all subsequent pages for that applicant are revisited
          and verified.
        </p>
      </div>

      {/*Applicant Dialogs started */}

      <Dialog
        open={isOpenEmailDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="modal row">
          <div className="modal-content col-md-5 border p-4">
            <span style={{ color: "black", fontWeight: "bold" }}>
              {" "}
              Applicant Basic Details
            </span>

            <div className="row ">
              <div className="col-12 col-md-6"></div>
              <div className="checkbox col-12 col-md-6 mb-5 d-flex">
                <input
                  type="checkbox"
                  id="agree"
                  onChange={handleAgreeChange}
                  // checked={isTermsAccepted}
                  // disabled={isCheckboxDisabled || isTermsAccepted}
                  className="mt-1"
                />
                <label
                  htmlFor="agree"
                  style={{ color: isCheckboxDisabled ? "#888" : "inherit" }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      color: "black",
                    }}
                  >
                    This Applicant is deceased
                  </span>{" "}
                  <br />
                  <i style={{ fontSize: "12px", color: "dark-gray" }}>
                    {" "}
                    None of the below details are required for deceased
                    applicant
                  </i>
                </label>
              </div>

              <div className="col-12 col-md-6 mt-3 ">
                <ArthaTextField
                  id="outlined-basic"
                  inputProps={{ maxLength: 10 }}
                  maxLength={10}
                  className={`form-control fs-5 custom-disabled-input mobile-input ${
                    isValidPanCard ? "" : "error"
                  }`}
                  label="PAN Card No.(Optional)"
                  value={panCard}
                  onChange={handlePanCardChange}
                  fullWidth
                  variant="outlined"
                  dispatch={isAgreed}
                />
                <div
                  className={`pan-card-validation ${
                    isValidPanCard ? "valid-input" : "invalid-input"
                  }`}
                >
                  {isValidPanCard
                    ? ""
                    : "Invalid Pan Card. Please enter in the specified format."}
                </div>
              </div>

              <div className="col-12 col-md-6 mt-3">
                <ArthaTextField
                  id="outlined-basic"
                  inputProps={{ maxLength: 10 }}
                  value={mobile}
                  className={`form-control fs-5 custom-disabled-input mobile-input ${
                    isValidMobile ? "" : "error"
                  }`}
                  onChange={handleMobileChange}
                  label="Mobile No"
                  fullWidth
                  variant="outlined"
                  disabled={isDeceased}  // Disable based on the deceased state
                />

                <div
                  className={`otp-verification-will ${
                    isValidMobile ? "valid-input" : "invalid-input"
                  }`}
                >
                  {isValidMobile
                    ? "OTP verification will be needed."
                    : "Enter 10 digit valid number!"}
                </div>
              </div>

              <div className="col-12 col-md-6 mt-4">
                <ArthaFormControl fullWidth>
                  <ArthaInputLabel
                    id="employmentType-label"
                    name="employmentType"
                    style={{ background: "white" }}
                  >
                    Employment Type
                  </ArthaInputLabel>
                  <Select
                    labelId="employmentType-label"
                    required
                    value={employmentType}
                    onChange={handleChangeEmp}
                    fullWidth
                    variant="outlined"
                    disabled={isDeceased}  // Disable based on the deceased state
                  >
                    {tenureDropdown &&
                      tenureDropdown.map((item) => {
                        return (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {/* <p className="msg">Preferred Tenure</p> */}
                </ArthaFormControl>
              </div>
            </div>

            <div className="row mt-4 mx-auto">
              <div className="col-6 d-flex justify-content-end mt-4">
                <ArthaClearButton
                  variant="contained"
                  onClick={closeEmailDialog}
                >
                  Cancel
                </ArthaClearButton>
              </div>
              <div className="col-6 d-flex justify-content-start mt-4">
                <ArthaContinueButton
                  variant="contained"
                  disabled={isValidMobile === false && isValidPanCard === false} // Disable button if email format is invalid
                  onClick={() => {
                    // handleContinue();
                    openOTPDialog();
                  }}
                  style={{
                    opacity: isButtonClicked ? 0.39 : 1,
                    pointerEvents: isButtonClicked ? "none" : "auto",
                  }}
                >
                  {isLoading1 ? (
                    <LoadingSpinner style={{ pointerEvents: "none" }} />
                  ) : (
                    "Continue"
                  )}
                </ArthaContinueButton>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={isOpenOTPDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="otpBox">
          <div className="AdharOTPForm">
            <div className="AdharOTPHeading mt-3">
              <h5>Verify OTP</h5>

              <p className=" mt-4" style={{ padding: "0", margin: "0" }}>
                Enter the 6-digit code we have sent to
              </p>
              <span
                className="msg mt-4"
                style={{
                  fontSize: "10px",
                  color: "blue",
                  padding: "0",
                  margin: "0",
                }}
              >
                {formData.mobile}
              </span>

              <div className="mt-5">
                <div>
                  <div>
                    {otp.map((value, index) => (
                      <input
                        key={index}
                        type="text"
                        className={`otp-input ${isInvalidOtp ? "error" : ""}`}
                        maxLength="1"
                        value={value}
                        onChange={(e) => handleOtpChange(index, e.target.value)}
                        onKeyDown={(e) => handleInputKeyDown(index, e)}
                        ref={(ref) => (inputRefs.current[index] = ref)} // Store the reference to each input field
                      />
                    ))}
                  </div>

                  <br />

                  <h6 className="text-center">
                    Didn't Receive any Code?{" "}
                    <a
                      href="#"
                      style={{
                        color: "rgb(255, 136, 0)",
                        marginLeft: "5px",
                      }}
                    >
                      <h6 className="mt-3">Resend Code</h6>
                    </a>
                  </h6>
                </div>
                <div className="row mt-5 ">
                  <div className="col-6 d-flex justify-content-end">
                    <ArthaClearButton
                      variant="contained"
                      onClick={() => {
                        setOpenOTPDialog(false);
                      }}
                    >
                      Back
                    </ArthaClearButton>
                  </div>

                  <div className="col-6 d-flex justify-content-start">
                    <ArthaContinueButton
                      onClick={handleVerifyClick}
                      variant="contained"
                      disabled={isInvalidOtp}
                      style={{
                        opacity: isButtonClicked ? 0.39 : 1,
                        pointerEvents: isButtonClicked ? "none" : "auto",
                      }}
                    >
                      {isLoading2 ? (
                        <LoadingSpinner style={{ pointerEvents: "none" }} />
                      ) : (
                        "Verify"
                      )}
                    </ArthaContinueButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/*Applicant Dialogs ended */}

      <div className="row">
        <div className="col-12 footer p-2">
          <div className="row">
            <div className="col-8"></div>
            <div className="col-4">
              <div className="row mb-2">
                <div className="col-7 d-flex justify-content-end">
                  <ArthaCancelButton
                    onClick={handleBack}
                    type="button"
                    style={{ width: "120px", height: "40px" }}
                  >
                    Back
                  </ArthaCancelButton>
                </div>
                <div className="col-5">
                  <ArthaApplyButton
                    type="submit"
                    onClick={handleCalculate}
                    style={{ width: "120px", height: "40px", opacity: "0.5" }}
                    // disabled={isLoading}
                    disabled
                  >
                    {isLoading ? "Continue" : "Continue"}
                  </ArthaApplyButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicantSummaryDetails;
