import React, { useEffect, useState, useRef } from "react";
import { FaEdit } from "react-icons/fa";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import ArthaClearButton from "../../fields/ArthaClearButton";
import AllOffers from "./AllOffers";
import SanctionLetter from "./SanctionLetter";
import Congrats from "./MessagePages/Congrats";
import EditRequirement from "./EditRequirement"; // Replace with the correct path
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "./SessionExpiredApi";
import LoadingSpinner from "../../fields/spinner";
import ArthaTextField from "../../fields/ArthaTextField";
import ArthaApplyButton from "../../fields/ArthaApplyButton";
import { Button } from "@mui/material";
import {
  businessLoanAcceptedLoanOfferAPI,
  businessLoanLoanOffersInfo,
  businessLoanSanctionLetterAndMitcGenerationAPI,
} from "../../actioncreators/actioncreators";
import { toast } from "react-toastify";
const LoanOffer = ({ onBack, onContinue }) => {
  const [tenureLength, setTenureLength] = React.useState();
  const [showSanctionLetter, setShowSanctionLetter] = useState(false);
  const [showEditRequirement, setShowEditRequirement] = useState(false);
  const [updatedOffer, setUpdatedOffer] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { ap_id, loan_app_id, response } = location.state || {};

  const [feeCalculated, setFeeCalculated] = useState(false);
  const [stateResponse, setStateResponse] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await businessLoanLoanOffersInfo();
        setStateResponse(response?.data);
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching user data:", error);
      }
    };
    fetchData();
  }, [ap_id, loan_app_id, navigate]);

  useEffect(() => {
    if (stateResponse && stateResponse?.loanOfferData) {
      setTenureLength(
        stateResponse?.loanOfferData?.foirCheckJson?.[0]?.tenure?.length
      );
    }
  }, [stateResponse]);

  useEffect(() => {
    if (stateResponse && stateResponse?.loanOfferData && !feeCalculated) {
      // Calculate the fee value as a percentage of loanAmount
      const updatedLoanOfferJson =
        stateResponse?.loanOfferData?.loanOfferJson?.map((offer) => {
          const feePercentage = parseFloat(offer.fee.replace("%", "")) / 100;
          const calculatedFee = offer.loanAmount * feePercentage;

          return {
            ...offer,
            fee: calculatedFee.toFixed(2), // Set fee as a decimal with 2 decimal places
          };
        });

      // Update only the necessary fields in the state
      setStateResponse((prevStateResponse) => ({
        ...prevStateResponse,
        loanOfferData: {
          ...prevStateResponse?.loanOfferData,
          loanOfferJson: updatedLoanOfferJson,
        },
      }));

      // Set feeCalculated to true to avoid an infinite loop
      setFeeCalculated(true);
    }
  }, [stateResponse, feeCalculated]);

  const setShowAllOffersFun = (data) => {
    setShowSanctionLetter(false);
    setShowEditRequirement(false);
    setUpdatedOffer(data);
  };
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };
  const handleBack = () => {
    onBack();
  };

  // const handleContinue = () => {
  //   setShowAllOffers( true );
  // };

  const handleAcceptButton = async () => {
    // Set loading state to true
    setIsButtonClicked(true);
    setIsLoading(true);

    let loanOffer = {};
    if (updatedOffer) {
      updatedOffer.lenderId =
        stateResponse?.loanOfferData?.loanOfferJson[0]?.lenderId;
      updatedOffer.associationType =
        stateResponse?.loanOfferData?.loanOfferJson[0]?.associationType;
      loanOffer = updatedOffer;
    } else {
      loanOffer = stateResponse?.loanOfferData?.loanOfferJson[0];
    }

    const requestData = {
      loan_amount: loanOffer.loanAmount,
      loan_tenure: loanOffer.loanTenure,
      interest_rate: loanOffer.rate,
      emi: loanOffer.emi,
      fee: parseFloat(loanOffer.fee),
      lender_id: loanOffer.lenderId,
      association_type: loanOffer.associationType,
    };

    const headers = {
      "Content-Type": "application/json",
      "x-apid": ap_id, // Make sure ap_id is defined
      "x-loanappid": loan_app_id, // Make sure loan_app_id is defined
    };

    try {
      const apiResponse = await businessLoanAcceptedLoanOfferAPI(requestData);
      if (
        apiResponse.status === 200 &&
        apiResponse.data.businessStatusCode === 2
      ) {
        if (apiResponse.data) {
          const nextApiResponse =
            await businessLoanSanctionLetterAndMitcGenerationAPI();
          const responseBody = nextApiResponse.data;
          if (
            nextApiResponse.status === 200 &&
            nextApiResponse.data.businessStatusCode === 2
          ) {
            navigate(SanctionLetter, {
              state: {
                response: responseBody,
              },
            });
            onContinue();
            setShowSanctionLetter(true);
          } else {
            setShowSanctionLetter(false);
          }
        }
      } else {
        navigate("/home/completedapplication");
      }
    } catch (error) {
      handleApiError(error);
      console.log(error);
      console.error("Error:", error);
    } finally {
      // Reset loading state after the operation is complete
      setIsLoading(false);
      setIsButtonClicked(false);
    }
  };

  const handleContinue = async () => {
    // Set loading state to true
    setIsButtonClicked(true);
    setIsLoading1(true);

    let loanOffer = {};
    if (updatedOffer) {
      updatedOffer.lenderId =
        stateResponse?.loanOfferData?.loanOfferJson[0]?.lenderId;
      updatedOffer.associationType =
        stateResponse?.loanOfferData?.loanOfferJson[0]?.associationType;
      loanOffer = updatedOffer;
    } else {
      loanOffer = stateResponse?.loanOfferData?.loanOfferJson[0];
    }

    const requestData = {
      loan_amount: loanOffer.loanAmount,
      loan_tenure: loanOffer.loanTenure,
      interest_rate: loanOffer.rate,
      emi: loanOffer.emi,
      fee: parseFloat(loanOffer.fee),
      lender_id: loanOffer.lenderId,
      association_type: loanOffer.associationType,
    };

    try {
      const apiResponse = await businessLoanAcceptedLoanOfferAPI(requestData);
      if (
        apiResponse.status === 200 &&
        apiResponse.data.businessStatusCode === 2
      ) {
            onContinue();
            setShowSanctionLetter(true);
          } else {
            setShowSanctionLetter(true);
          }
        } catch (error) {
      handleApiError(error);
      console.log(error);
      console.error("Error:", error);
    } finally {
      // Reset loading state after the operation is complete
      setIsLoading1(false);
      setIsButtonClicked(false);
    }
  };
  return (
    <>
      {!showSanctionLetter && !showEditRequirement ? (
        <>
          <div className="row">
            <div className="AdharOTPHeading col-12 mt-2">
              <h6>
                Congrats! You have passed all our checks and qualify for the
                following offer
              </h6>
              <p className="offer-page-heading">
                Offer is based on your credit score
              </p>
            </div>
          </div>

          {stateResponse &&
            stateResponse?.loanOfferData &&
            stateResponse?.loanOfferData?.loanOfferJson &&
            stateResponse?.loanOfferData?.loanOfferJson?.length > 0 &&
            stateResponse?.loanOfferData?.loanOfferJson?.map((item, index) => {
              // const formattedEMI = `₹ ${(item.emi.toFixed(2)).toLocaleString('en-IN')}`;
              const formattedEMI = `₹ ${Number(item.emi).toLocaleString(
                "en-IN",
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
              )}`;
              const formattedFee = `₹ ${Number(item.fee).toLocaleString(
                "en-IN",
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
              )}`;

              return (
                <div className="row mt-4 d-flex justify-content-center">
                  {updatedOffer ? (
                    <div className="col-11 shadow-none loan-offer-card card">
                      <div className="row card-body d-flex justify-content-center">
                        <div className="col-4  text-center">
                          <h6>Updated Loan Offer</h6>
                        </div>
                        <div className="row mt-3">
                          <div className="col-4">
                            <p className="offer-page-heading">EMI/Month</p>
                            <h4 className="loan-offer-amount">
                              ₹{updatedOffer.emi.toFixed(2)}
                            </h4>
                          </div>
                          <div className="col-3">
                            <p className="offer-page-heading">Loan Amount</p>
                            <p className="loan-offer-amount2">
                              ₹ {updatedOffer.loanAmount}
                              {/* <FaEdit
                                onClick={() => setShowEditRequirement( true )}
                              /> */}
                            </p>
                          </div>
                          <div className="col-3">
                            <p className="offer-page-heading">Tenure</p>
                            <p className="loan-offer-amount2">
                              {updatedOffer.loanTenure} mths
                            </p>
                          </div>
                          <div className="col-2">
                            <p className="offer-page-heading">interest Rate</p>
                            <p className="loan-offer-amount2">
                              <p className="loan-offer-amount2">
                                {(updatedOffer.rate * 100).toFixed(2)}%
                              </p>{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-10 shadow-none loan-offer-card card">
                      <div className="row card-body d-flex justify-content-center">
                        <div className="col-4  text-center">
                          <h6>Loan Offer</h6>
                        </div>
                        <div className="row mt-3">
                          <div className="col-4">
                            <p className="offer-page-heading">EMI / Month</p>
                            <h5 className="loan-offer-amount fs-4">
                              {formattedEMI}
                            </h5>
                          </div>
                          <div className="col-3">
                            <p className="offer-page-heading">Loan Amount</p>
                            <p className="loan-offer-amount2 fs-6">
                              ₹ {item.loanAmount.toLocaleString("en-IN")}
                              {/* <FaEdit onClick={() => setShowEditRequirement( true )} /> */}
                            </p>
                          </div>
                          <div className="col-3">
                            <p className="offer-page-heading">Tenure</p>
                            <p className="loan-offer-amount2 fs-6">
                              {item.loanTenure} Months
                            </p>
                          </div>
                          <div className="col-2">
                            <p className="offer-page-heading">Interest Rate</p>
                            <p className="loan-offer-amount2 fs-6">
                              {(item.rate * 100).toFixed(2)}%
                            </p>
                          </div>

                          <span className="d-flex">
                            <span style={{ fontSize: "13px" }}>
                              *Processing Fees
                            </span>
                            <span
                              style={{ fontSize: "13px", paddingLeft: "5px" }}
                            >
                              {formattedFee}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}

          <div className="row mt-3 mt-md-4 d-flex justify-content-center">
            <div className="col-sm-8 col-md-4 col-lg-4 ">
              <ArthaTextField
                name="promo"
                label="Promo Code"
                fullWidth
                inputProps={{ maxLength: 80 }}
              />
            </div>
            <div className="col-sm-4 col-md-2 col-lg-2 p-0">
              <Button
                variant="contained"
                className="upload_btn  apply_btn_white  shadow-none mt-2 "
                style={{ color: "orange !important" }}
              >
                Apply
              </Button>
            </div>
          </div>
          <div className="col-12 text-center mt-5">
            {stateResponse?.businessStatusSubCode === 2.1 && (
              <span
                style={{
                  color: "green",
                  fontWeight: 800,
                  fontSize: "14px",
                  cursor: "pointer",
                }}
              >
                You have already accepted the offer, please click on continue.
              </span>
            )}
          </div>

          <div className="row mt-4 ">
            <div className="col-6 d-flex justify-content-end">
              <ArthaContinueButton
                type="button"
                className="orange custom_btn"
                onClick={handleAcceptButton}
                style={{
                  opacity:
                    isButtonClicked ||
                    stateResponse?.businessStatusSubCode === 2.1
                      ? 0.39
                      : 1,
                  pointerEvents:
                    isButtonClicked ||
                    stateResponse?.businessStatusSubCode === 2.1
                      ? "none"
                      : "auto",
                }}
              >
                {isLoading ? (
                  <LoadingSpinner style={{ pointerEvents: "none" }} />
                ) : (
                  " Accept Offer"
                )}
              </ArthaContinueButton>
            </div>

            <div className="col-6 d-flex justify-content-start">
            <ArthaContinueButton
                type="button"
                className="orange custom_btn"
                onClick={handleContinue}
                style={{
                  opacity:
                    isButtonClicked ||
                    stateResponse?.businessStatusSubCode !== 2.1
                      ? 0.39
                      : 1,
                  pointerEvents:
                    isButtonClicked ||
                    stateResponse?.businessStatusSubCode !== 2.1
                      ? "none"
                      : "auto",
                }}
              >
                {isLoading1 ? (
                  <LoadingSpinner style={{ pointerEvents: "none" }} />
                ) : (
                  " Continue"
                )}
              </ArthaContinueButton>
            </div>
          </div>
        </>
      ) : null}

      {showSanctionLetter ? (
        <SanctionLetter
          onContinue={onContinue}
          onBack={onBack}
          onCancel={() => setShowSanctionLetter(false)}
        />
      ) : null}

      {showEditRequirement ? (
        <EditRequirement
          setShowAllOffersFun={setShowAllOffersFun}
          onCancel={() => setShowEditRequirement(false)}
          response={stateResponse}
          tenureLength={tenureLength}
        />
      ) : null}
    </>
  );
};

export default LoanOffer;
