import React, { useEffect, useState, useRef } from "react";
import { InputLabel, MenuItem, Select } from "@mui/material";
import ArthaTextField from "../../fields/ArthaTextField";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import ArthaFormControl from "../../fields/ArthaFormControl";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "./SessionExpiredApi";
import {
  businessLoanFetchDataAPI,
  buisnessLoanFetchReferncesAPI,
  businessLoanStoreReferncesAPI,
} from "../../actioncreators/actioncreators";
import LoadingSpinner from "../../fields/spinner";
import ITrDetails from "./ITR";
import { toast } from "react-toastify";

export default function Refernces({
  onBack,
  onContinue,
  onCancel,
  changeStatus,
}) {
  const [referenceCount, setReferenceCount] = useState(4); // Initial reference count
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [references, setReferences] = useState(
    Array.from({ length: referenceCount }, (_, index) => index + 1)
  );
  const [showItrDetails, setshowItrDetails] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [response, setresponse] = useState([]);
  // State to hold user input data for each reference
  const [referenceData, setReferenceData] = useState(
    Array.from({ length: referenceCount }, () => ({
      personName: "",
      contactNumber: "",
      address: "",
      email: "",
      name: "",
      referenceThreshold: "",
      referenceType: "",
    }))
  );
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };
  const [referenceThreshold, setReferenceThreshold] = useState({
    personal: { maxCount: 0, minCount: 0 },
    professional: { maxCount: 0, minCount: 0 },
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await buisnessLoanFetchReferncesAPI();
        // let response = {
        //   businessStatusCode: 2,
        //   httpResponseCode: 200,
        //   message: "Reference Details fetched Successfully",
        //   referenceThreshold: {
        //     personal: {
        //       maxCount: "2",
        //       minCount: "1",
        //     },
        //     professional: {
        //       maxCount: "2",
        //       minCount: "2",
        //     },
        //   },
        //   referencesDetails: null,
        //   // [
        //   //     {
        //   //         "address": "123,123",
        //   //         "contactNumber": "9626719998",
        //   //         "email": "abc@xyz.com",
        //   //         "name": "V Keerthi Vikram",
        //   //         "referenceType": "Personal"
        //   //     },
        //   //     {
        //   //         "address": "123,123",
        //   //         "contactNumber": "9626719998",
        //   //         "email": "abc@xyz.com",
        //   //         "name": "V Keerthi Vikram",
        //   //         "referenceType": "Personal"
        //   //     },
        //   //     {
        //   //         "address": "234,234",
        //   //         "contactNumber": "9970185666",
        //   //         "email": "abc@xyz.com",
        //   //         "name": "V Keerthi Vikram",
        //   //         "referenceType": "Professional"
        //   //     },
        //   //     {
        //   //         "address": "234,234",
        //   //         "contactNumber": "9970185666",
        //   //         "email": "abc@xyz.com",
        //   //         "name": "V Keerthi Vikram",
        //   //         "referenceType": "Professional"
        //   //     }
        //   // ]
        // };
        console.log(response);
        setresponse(response);
        setReferenceThreshold(response);
        // const { referencesDetails } = response;
        console.log("response", response);
        if (response?.data?.referencesDetails) {
          setReferenceData(response?.data?.referencesDetails);
        } else {
          // Initialize empty reference data based on max counts
          // console.log("response",response);
          // console.log("referenceThreshold",referenceThreshold);
          // console.log("referenceThreshold?.personal?.maxCount",referenceThreshold?.personal?.maxCount);
          const totalCount =
            Number(response?.data?.referenceThreshold?.personal?.maxCount) +
            Number(response?.data?.referenceThreshold?.professional?.maxCount);
          console.log("totalCount", totalCount);
          setReferenceData(Array.from({ length: totalCount }, () => ({
            name: "",
            contactNumber: "",
            address: "",
            email: "",
            referenceType: "",
          })));
        }
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const professionalMaxCount =
      Number(referenceThreshold?.data?.referenceThreshold?.professional?.maxCount) || 0;
    const personalMaxCount =
      Number(referenceThreshold?.data?.referenceThreshold?.personal?.maxCount) || 0;
    const totalReferenceCount = professionalMaxCount + personalMaxCount;
    setReferenceCount(totalReferenceCount);
  }, [response]); // Depend on response to update when it changes

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      const payload = {
        type: "REFERENCE_TYPE",
        key: "REFERENCE_TYPE",
        source: "json_value",
      };
      try {
        const dropdownResponse = await businessLoanFetchDataAPI(payload); // Replace with your dropdown options API call function
        if (dropdownResponse.data && dropdownResponse.data[0].List) {
          setDropdownOptions(dropdownResponse.data[0].List);
        }
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching dropdown options:", error);
      }
    };

    fetchDropdownOptions();
  }, []);

  const [formValid, setFormValid] = useState(true);
  const handleKeyPress = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  // const handleInputChange = (index, fieldName, value) => {
  //   const updatedData = [...referenceData];
  //   if (!updatedData[index]) {
  //     updatedData[index] = {}; // Ensure the object exists
  //   }
  //   updatedData[index][fieldName] = value;
  //   setReferenceData(updatedData);

  //   // Initialize errors array if it doesn't exist
  //   const updatedErrors = [...errors];
  //   if (!updatedErrors[index]) {
  //     updatedErrors[index] = {};
  //   }

  //   // Validation functions
  //   const validateEmail = (email) => {
  //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //     return emailRegex.test(email);
  //   };

  //   const validateContactNumber = (number) => {
  //     const contactNumberRegex = /^\d{10}$/;
  //     const isAllSameDigit = /^(\d)\1{9}$/; // Regex to check if all digits are the same

  //     if (!contactNumberRegex.test(number)) {
  //       return "Contact No should be 10 digits";
  //     } else if (isAllSameDigit.test(number)) {
  //       return "Contact No should not be all the same digit";
  //     }
  //     return "";
  //   };

  //   const validateName = (name) => {
  //     const specialCharRegex = /[^a-zA-Z ]/g;
  //     const digitRegex = /\d/;

  //     if (specialCharRegex.test(name)) {
  //       return "Name should not contain special characters.";
  //     }
  //     if (digitRegex.test(name)) {
  //       return "Name should not contain digits.";
  //     }
  //     if (name.length > 50) {
  //       return "Name should not exceed 50 characters.";
  //     }
  //     return "";
  //   };

  //   // Validation logic
  //   if (fieldName === "email" && value && !validateEmail(value)) {
  //     updatedErrors[index][fieldName] =
  //       "Invalid Email. Please enter a valid email address.";
  //   } else if (fieldName === "contactNumber") {
  //     const contactNumberError = validateContactNumber(value);
  //     if (contactNumberError) {
  //       updatedErrors[index][fieldName] = contactNumberError;
  //     } else {
  //       updatedErrors[index][fieldName] = "";
  //     }
  //   } else if (
  //     (fieldName === "name" || fieldName === "address") &&
  //     !value.trim()
  //   ) {
  //     updatedErrors[index][fieldName] = `${
  //       fieldName.charAt(0).toUpperCase() + fieldName.slice(1)
  //     } is required`;
  //   } else if (fieldName === "name") {
  //     const nameError = validateName(value);
  //     if (nameError) {
  //       updatedErrors[index][fieldName] = nameError;
  //     } else {
  //       updatedErrors[index][fieldName] = "";
  //     }
  //   } else if (fieldName === "address") {
  //     const min = 5;
  //     const max = 350;
  //     if (value.length < min) {
  //       updatedErrors[index][
  //         fieldName
  //       ] = `Address must be at least ${min} characters`;
  //     } else if (value.length > max) {
  //       updatedErrors[index][
  //         fieldName
  //       ] = `Address must be at most ${max} characters`;
  //     } else {
  //       updatedErrors[index][fieldName] = "";
  //     }
  //   } else {
  //     updatedErrors[index][fieldName] = "";
  //   }

  //   setErrors(updatedErrors);

  //   // Check form validity
  //   setFormValid(isFormValid(updatedErrors));
  // };

  const handleInputChange = (index, fieldName, value) => {
    const updatedData = [...referenceData];
    if (!updatedData[index]) {
      updatedData[index] = {}; // Ensure the object exists
    }
    updatedData[index][fieldName] = value;
    setReferenceData(updatedData);

    // Initialize errors array if it doesn't exist
    const updatedErrors = [...errors];
    if (!updatedErrors[index]) {
      updatedErrors[index] = {};
    }

    // Validation functions
    const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    const validateContactNumber = (number) => {
      const contactNumberRegex = /^\d{10}$/;
      const isAllSameDigit = /^(\d)\1{9}$/; // Regex to check if all digits are the same

      if (!contactNumberRegex.test(number)) {
        return "Contact No should be 10 digits";
      } else if (isAllSameDigit.test(number)) {
        return "Contact No should not be all the same digit";
      }
      return "";
    };

    const validateName = (name) => {
      const specialCharRegex = /[^a-zA-Z ]/g;
      const digitRegex = /\d/;

      if (specialCharRegex.test(name)) {
        return "Name should not contain special characters.";
      }
      if (digitRegex.test(name)) {
        return "Name should not contain digits.";
      }
      if (name.length > 50) {
        return "Name should not exceed 50 characters.";
      }
      return "";
    };

    // Validation logic
    if (fieldName === "email" && value && !validateEmail(value)) {
      updatedErrors[index][fieldName] =
        "Invalid Email. Please enter a valid email address.";
    } else if (fieldName === "contactNumber") {
      const contactNumberError = validateContactNumber(value);
      if (contactNumberError) {
        updatedErrors[index][fieldName] = contactNumberError;
      } else {
        updatedErrors[index][fieldName] = "";
      }
    } else if (
      (fieldName === "name" || fieldName === "address") &&
      !value.trim()
    ) {
      updatedErrors[index][fieldName] = `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)
        } is required`;
    } else if (fieldName === "name") {
      const nameError = validateName(value);
      if (nameError) {
        updatedErrors[index][fieldName] = nameError;
      } else {
        updatedErrors[index][fieldName] = "";
      }
    } else if (fieldName === "address") {
      const min = 5;
      const max = 350;
      if (value.length < min) {
        updatedErrors[index][
          fieldName
        ] = `Address must be at least ${min} characters`;
      } else if (value.length > max) {
        updatedErrors[index][
          fieldName
        ] = `Address must be at most ${max} characters`;
      } else {
        updatedErrors[index][fieldName] = "";
      }
    } else if (fieldName === "referenceType") {
      const professionalCount = referenceData.filter(
        (ref) => ref.referenceType === "professional"
      ).length;
      const personalCount = referenceData.filter(
        (ref) => ref.referenceType === "personal"
      ).length;

      const professionalMaxCount =
        Number(referenceThreshold?.data?.referenceThreshold?.professional?.maxCount) || 0;
      const professionalMinCount =
        Number(referenceThreshold?.data?.referenceThreshold?.professional?.minCount) || 0;
      const personalMaxCount =
        Number(referenceThreshold?.data?.referenceThreshold?.personal?.maxCount) || 0;
      const personalMinCount =
        Number(referenceThreshold?.data?.referenceThreshold?.personal?.minCount) || 0;

      let isValid = true;

      // Check for exceeding maximum count
      if (professionalCount > professionalMaxCount) {
        updatedErrors[index][fieldName] =
          "Exceeded maximum number of Professional references.";
        isValid = false;
      } else if (personalCount > personalMaxCount) {
        updatedErrors[index][fieldName] =
          "Exceeded maximum number of Personal references.";
        isValid = false;
      }
      // Check for not meeting minimum count
      else if (
        professionalCount < professionalMinCount &&
        referenceData[index].referenceType === "professional"
      ) {
        updatedErrors[index][fieldName] =
          `At least ${professionalMinCount} Professional references are required.`;
        isValid = false;
      } else if (
        personalCount < personalMinCount &&
        referenceData[index].referenceType === "personal"
      ) {
        updatedErrors[index][fieldName] =
          `At least ${personalMinCount} Personal references are required.`;
        isValid = false;
      } else {
        updatedErrors[index][fieldName] = ""; // Clear the error if valid
      }

      // If minimum references are met, mark as valid
      if (professionalCount >= professionalMinCount && personalCount >= personalMinCount) {
        isValid = true;
      } else {
        isValid = false;
      }

      // You can now update the form state, validation state, or other logic based on `isValid`
      setFormValid(isValid); // Example function to handle form validation status
    } else {
      updatedErrors[index][fieldName] = "";
    }

    setErrors(updatedErrors);

    // Check form validity
    setFormValid(isFormValid(updatedErrors));

    if (fieldName === "referenceType") {
      const professionalCount = updatedData.filter(ref => ref.referenceType === "professional").length;
      const personalCount = updatedData.filter(ref => ref.referenceType === "personal").length;

      const professionalMaxCount = Number(referenceThreshold?.data?.referenceThreshold?.professional?.maxCount) || 0;
      const personalMaxCount = Number(referenceThreshold?.data?.referenceThreshold?.personal?.maxCount) || 0;

      if (value === "professional" && professionalCount > professionalMaxCount) {
        updatedErrors[index][fieldName] = `Maximum ${professionalMaxCount} Professional references allowed.`;
      } else if (value === "personal" && personalCount > personalMaxCount) {
        updatedErrors[index][fieldName] = `Maximum ${personalMaxCount} Personal references allowed.`;
      } else {
        updatedErrors[index][fieldName] = "";
      }

      // Update form validity
      setFormValid(isFormValid());
    }

  };

  const isFormValid = () => {
    const professionalCount = referenceData.filter(ref => ref.referenceType === "professional").length;
    const personalCount = referenceData.filter(ref => ref.referenceType === "personal").length;
    console.log("personalCount", personalCount);
    console.log("professionalCount", professionalCount);
    const professionalMinCount = Number(referenceThreshold?.data?.referenceThreshold?.professional?.minCount) || 0;
    const personalMinCount = Number(referenceThreshold?.data?.referenceThreshold?.personal?.minCount) || 0;
    console.log("personalMinCount", personalMinCount);
    console.log("professionalMinCount", professionalMinCount);
    console.log("professionalCount < professionalMinCount || personalCount < personalMinCount", professionalCount < professionalMinCount || personalCount < personalMinCount)
    if (professionalCount < professionalMinCount || personalCount < personalMinCount) {
      return false;
    }
    console.log("referenceData", referenceData);
    // Check if all required fields are filled and valid
    return referenceData.every(ref =>
      !ref.referenceType || // Skip validation for empty referenceType
      (ref.referenceType && ref.name && ref.contactNumber && ref.address &&
        !errors.some(error => Object.values(error).some(e => e)))
    );
  };

  // Function to check if all required fields in all references are filled
  const isContinueButtonDisabled = () => {
    for (const reference of referenceData) {
      if (
        !reference.name ||
        !reference.contactNumber ||
        !reference.address ||
        !reference.referenceType
      ) {
        return true; // Disable button if any field is empty
      }
    }
    return false; // Enable button if all fields are filled
  };
  const handleBack = () => {
    if (!onCancel()) {
      changeStatus("EMPLOYMENT_DETAILS");
    }
  };

  const handleContinue = async () => {
    if (!isFormValid()) {
      toast.error("Please fill all required fields and ensure the minimum number of references for each type.");
      return;
    }
    setIsButtonClicked(true);
    setIsLoading(true);

    try {
      // Generate the payload dynamically based on the user input
      const payload = {
        referencesDetails: referenceData.filter(ref => ref.referenceType).map(ref => ({
          name: ref.name,
          address: ref.address,
          referenceType: ref.referenceType,
          contactNumber: ref.contactNumber,
          email: ref.email,
        })),
      };

      // Call the API with the payload
      const response = await businessLoanStoreReferncesAPI(payload);

      // Check the response and take action accordingly
      if (response.status === 200) {
        // Handle success, you can navigate or trigger the next action
        if (response.data.businessStatusCode === 2) {
          setshowItrDetails(true);
        } else if (response.data.businessStatusCode === 1) {
          toast.error(response.data.message);
        }
      } else {
        // Handle other response statuses if needed
        toast.error(response.data.message);
      }
    } catch (error) {
      // Handle errors
      handleApiError(error);
      console.error("Error fetching loan data:", error);
    } finally {
      // Reset loading state after the operation is complete
      setIsLoading(false);
      setIsButtonClicked(false);
    }
  };

  const [errors, setErrors] = useState([]);
  console.log(referenceThreshold);

  return (
    <>
      {!showItrDetails ? (
        <>
          <form className="kycform">
            <h6 className="mt-2">References</h6>
            <p className="note-text" style={{ color: "#2a2a2a" }}>
              References cannot be from relatives. Minimum required:
              {referenceThreshold?.data?.referenceThreshold?.professional?.minCount} Professional and
              {referenceThreshold?.data?.referenceThreshold?.personal?.minCount} Personal references.
            </p>
            {referenceData.map((reference, index) => (
              <div key={index}>
                <h6 className="mt-2">Reference {index + 1} :</h6>
                <div className="row mt-4">
                  <div className="col-12 col-md-6">
                    <ArthaTextField
                      name={`name`}
                      fullWidth
                      label={`Reference Name`}
                      value={reference.name}
                      onChange={(e) =>
                        handleInputChange(index, "name", e.target.value)
                      }
                      error={!!errors[index]?.name}
                      helperText={errors[index]?.name}
                    />
                  </div>

                  <div className="col-md-6 mt-3 mt-md-0">
                    <ArthaTextField
                      name={`contactNumber`}
                      fullWidth
                      label={`Contact Number (Landline/Mobile No)`}
                      inputProps={{ maxLength: 10 }}
                      onKeyPress={handleKeyPress}
                      value={reference.contactNumber}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "contactNumber",
                          e.target.value
                        )
                      }
                      error={!!errors[index]?.contactNumber}
                      helperText={errors[index]?.contactNumber}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-3">
                    <ArthaTextField
                      name={`address`}
                      min={5}
                      max={350}
                      fullWidth
                      label={`Address `}
                      value={reference.address}
                      onChange={(e) =>
                        handleInputChange(index, "address", e.target.value)
                      }
                      error={!!errors[index]?.address}
                      helperText={errors[index]?.address}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-3">
                    <ArthaTextField
                      name={`email`}
                      fullWidth
                      label={`Email (Optional)`}
                      value={reference.email}
                      onChange={(e) =>
                        handleInputChange(index, "email", e.target.value)
                      }
                      error={!!errors[index]?.email}
                      helperText={errors[index]?.email}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-3">
                    <ArthaFormControl fullWidth>
                      <InputLabel id={`relationship-label`}>
                        Reference Type
                      </InputLabel>
                      <Select
                        labelId={`relationship-label`}
                        id={`referenceType`}
                        name={`referenceType`}
                        value={reference.referenceType}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "referenceType",
                            e.target.value
                          )
                        }
                        error={!!errors[index]?.referenceType}
                      >
                        <MenuItem value="personal">Personal</MenuItem>
                        <MenuItem value="professional">Professional</MenuItem>
                        {dropdownOptions.map((option, i) => (
                          <MenuItem key={i} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors[index]?.referenceType && (
                        <p className="error-text">
                          {errors[index].referenceType}
                        </p>
                      )}
                    </ArthaFormControl>
                  </div>
                </div>
              </div>
            ))}

            <div className="row mt-4">
              <div className="col-6 d-flex justify-content-end">
                <ArthaClearButton variant="contained" onClick={handleBack}>
                  Back
                </ArthaClearButton>
              </div>
              <div className="col-6 d-flex justify-content-start">
                <ArthaContinueButton
                  variant="contained"
                  onClick={handleContinue}
                  disabled={
                    // isContinueButtonDisabled() ||
                    isLoading ||
                    isButtonClicked ||
                    !isFormValid()
                  }
                //disabled={!formValid}
                >
                  {isLoading ? (
                    <LoadingSpinner style={{ pointerEvents: "none" }} />
                  ) : (
                    "Continue"
                  )}
                </ArthaContinueButton>
              </div>
            </div>
          </form>
        </>
      ) : (
        <ITrDetails
          onContinue={onContinue}
          // onCancel={onCancel}
          changeStatus={changeStatus}
          onCancel={() => setshowItrDetails(false)}
        />
      )}
    </>
  );
}
