import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Home from "./DrawerIcons/home.svg";
import Myloan from "./DrawerIcons/my-loans.svg";
import CreditScore from "./DrawerIcons/credit-score.svg";
import EmiCalci from "./DrawerIcons/emi-calculator.svg";
import LoanTypes from "./DrawerIcons/loan-types.svg";
import GreaterThan from "./DrawerIcons/Chev1_right.svg";
import "./sidebar.css"; // Import your CSS file for styling

const Drawer = ({ response4 }) => {
  const [activeMainIndex, setActiveMainIndex] = useState(null);
  const [activeSubIndex, setActiveSubIndex] = useState(null);
  const [submenuVisible, setSubmenuVisible] = useState(false);
  const [isTypesOfLoanOpen, setTypesOfLoanOpen] = useState(false);
  const [enableApplyButton, setEnableApplyButton] = useState([]);
  const [label, setLabel] = useState("");

  const handleMainTabClick = (label, index) => {
    if (activeMainIndex === index && submenuVisible) {
      setSubmenuVisible(false);
      setLabel("");
    } else {
      setActiveMainIndex(index);
      setSubmenuVisible(true);
      setLabel(label);
    }
  };

  useEffect(() => {
    setEnableApplyButton(response4?.data?.[0]?.values || []);
  }, [response4]);
  console.log("enableApplyButton",enableApplyButton)

  const handleSubTabClick = (mainIndex, subIndex) => {
    setActiveMainIndex(mainIndex);
    setActiveSubIndex(subIndex);
    setTypesOfLoanOpen(false); // Close the submenu when clicking on a sub tab
  };

  const toggleTypesOfLoan = () => {
    setTypesOfLoanOpen(!isTypesOfLoanOpen);
  };

  const menuItems = [
    {
      icon: <img src={Home} alt="" className="home-icon" />,
      label: "Home",
      url: "",
    },
    {
      icon: <img src={Myloan} alt="" className="home-icon" />,
      label: "My Loans",
    },
    {
      icon: <img src={CreditScore} alt="" className="home-icon" />,
      label: "Credit Score",
    },
    {
      icon: <img src={EmiCalci} alt="" className="home-icon" />,
      label: "EMI Calculator",
    },
    {
      icon: <img src={LoanTypes} alt="" className="home-icon" />,
      label: "Types Of Loan",
      submenus: [
        enableApplyButton.includes("Personal Loan") && { label: "Personal Loan", url: "personalloans" },
        enableApplyButton.includes("Business Loan") && { label: "Business Loan", url: "businessloan" },
        enableApplyButton.includes("LAP") && { label: "LAP", url: "loanagainstproperty" },
        enableApplyButton.includes("Education Loan") && { label: "Education Loan", url: "educationloan" }
      ].filter(Boolean),
    }
    
    
  ];

  return (
    <div
      className="drawer"
      onMouseLeave={() => setSubmenuVisible(false)}
      onMouseEnter={() => setSubmenuVisible(true)}
    >
      <div className="left-nav">
        {menuItems.map((item, mainIndex) => (
          <div key={mainIndex} className="tab-container">
            <Link
              to={item.url}
              className={`left-nav-tab ${
                activeMainIndex === mainIndex ? "active" : ""
              }`}
              onClick={() => handleMainTabClick(item.label, mainIndex)}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {item.icon}
                <span>{item.label}</span>
              </div>
              {item.submenus &&
              item.submenus.length > 0 &&
              label === item.label ? (
                <img
                  src={GreaterThan}
                  alt=""
                  style={{ rotate: "90deg" }}
                  className="greater-than-icon d-flex"
                />
              ) : (
                <img
                  src={GreaterThan}
                  alt=""
                  className="greater-than-icon d-flex"
                />
              )}
            </Link>
            {item.submenus &&
              submenuVisible &&
              activeMainIndex === mainIndex && (
                <div className="submenu">
                  {item.submenus.map((submenu, subIndex) => (
                    <Link
                      key={subIndex}
                      to={submenu.url}
                      className={`submenu-item ${
                        activeSubIndex === subIndex ? "active" : ""
                      }`}
                      onClick={() => handleSubTabClick(mainIndex, subIndex)}
                      style={{
                        display: "block",
                        padding: "10px 15px 10px 50px",
                        color: "#333",
                        textAlign: "left",
                        textDecoration: "none",
                        fontSize: "13px",
                      }}
                    >
                      {submenu.label}
                    </Link>
                  ))}
                </div>
              )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Drawer;
